import { memo } from 'react';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';

import SearchbarInner from '../../SearchbarComponents/SearchbarInner';
import CTAbuttons from './CTAbuttons';
import UserLoginComponent from '../UserLoginComponent';
import ButtonComponent from '../../Buttons/ButtonComponent';
import withComponentName from '../../../decorators/withComponentName';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const SearchIcon = ({ toggleMobileSearchBar, showSearchBarIcon }) => (
  <div
    className={cx('search_icon_container', { show: showSearchBarIcon })}
    onClick={toggleMobileSearchBar}
  >
    <FontAwesomeIcon className={Styles.search_icon} icon={faSearch} />
  </div>
);

const MemoizedSearchIcon = memo(SearchIcon);

export const HeaderRightSide = ({
  sendPiwikGetStarted,
  hideSearchBar,
  showCTAButtons,
  piwikEnabled,
  width,
  path,
  showLeftCTA,
  toggleMobileSearchBar,
  auth,
  isRailsContext,
  showSearchBarIcon,
  homepageHeader,
  userMenuRef,
}) => {
  // detect where to show mobile user block on some product pages
  const showMobileUserBlock = width < 768 && [
    '/products/api/financial-news',
    '/financial-enterprise-solutions',
  ].includes(path);

  // detect where to show login button
  const showUserBlock = [
    '/products/api/financial-news/pricing-api/commercial',
    '/products/api/financial-news/pricing-api/noncommercial',
    '/products/api/financial-news/pricing-historical',
  ].includes(path);

  // show new design searchbar on chatbot page
  const showNewDesignSearchbar = [
    '/chatbot',
  ].includes(path);

  const rightSideClass = cx('right_side', {
    'hidden-login-section': !(!showCTAButtons || (showUserBlock || showMobileUserBlock)),
  });

  const showCTAButtonsCheck = showCTAButtons && !showLeftCTA;

  return (
    <div className={rightSideClass}>
      <div
        className={cx('searchbar_container', {
          hide_search_bar: hideSearchBar,
          show_on_bigger: showSearchBarIcon && width <= 2800,
          show_on_medium: !showSearchBarIcon,
        })}
      >
        <SearchbarInner
          CTAButtons={showCTAButtons ? 2 : 1}
          searchStyles={[
            'search_bar_full_width',
            'main-header-searchbar',
            'search_bar_light_width',
            (homepageHeader || showNewDesignSearchbar) && 'homepage_header',
          ]}
          fullWidth
          oneItem
          searchbarType="headerSearchbar"
          piwikEnabled={piwikEnabled}
          isRailsContext={isRailsContext}
          homepageHeader={homepageHeader}
        />
      </div>
      {(!hideSearchBar && ((width >= 1024 && width <= 2800) || !showCTAButtonsCheck)) && (
        <MemoizedSearchIcon
          toggleMobileSearchBar={toggleMobileSearchBar}
          showSearchBarIcon={showSearchBarIcon}
        />
      )}
      <div className={Styles.right_side_inner}>
        {showCTAButtonsCheck && (
          <CTAbuttons
            path={path}
            width={width}
            isRailsContext={isRailsContext}
          />
        )}
        <div className={Styles.user_block}>
          <UserLoginComponent
            auth={auth}
            piwikEnabled={piwikEnabled}
            width={width}
            headerType="homePageHeader"
            isRailsContext={isRailsContext}
            ref={userMenuRef}
          />
        </div>
      </div>
      <div className={Styles.get_started_button_container}>
        <ButtonComponent
          withoutReload={!isRailsContext}
          value="Get Started"
          short
          green
          path="/news/onboarding/watchlist"
          checkEvent={sendPiwikGetStarted}
        />
      </div>
    </div>
  );
};

export default withComponentName(HeaderRightSide);
