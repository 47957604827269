import { Component } from 'react';
import classNames from 'classnames/bind';
import { isIOS } from 'react-device-detect';

import withComponentName from '../../../../decorators/withComponentName';
import windowWidthDecorator from '../../../../decorators/windowWidthDecorator';

import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

class CTAbuttons extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.scrollToSignupForm = this.scrollToSignupForm.bind(this);
  }

  scrollToSignupForm(e) {
    if (isIOS || window.innerWidth <= 480) {
      e.preventDefault();
    }
    document.querySelector('#contactus-form').scrollIntoView({
      behavior: 'smooth',
    });

    setTimeout(() => {
      this.closeMobileMenu();
    }, 100);
    e.stopPropagation();
  }

  closeMobileMenu() {
    const { fromMobileMenu, closeMenu } = this.props;

    if (fromMobileMenu) {
      closeMenu();
    }
  }

  render() {
    const {
      path, ctaButtonType, isRailsContext, windowWidth,
    } = this.props;

    const showTryOurApi = path !== '/products/api/financial-news' && path.substr(0, 36) !== '/products/api/financial-news/pricing';

    const contactUsLeadsToForm = [
      '/products/api/financial-news',
      '/products/api/financial-news/pricing-api/commercial',
      '/products/api/financial-news/pricing-api/noncommercial',
      '/products/api/financial-news/pricing-historical',
    ].indexOf(path) !== -1;

    const CTAButtonsClass = cx('cta-buttons', {
      'with-try-api': showTryOurApi,
      'financial-news-cta': '/products/api/financial-news'.indexOf(path) !== -1,
      'cta-anchor-button': ctaButtonType === 'mobileAnchorButton',
    });

    return (
      <div className={CTAButtonsClass}>
        {showTryOurApi && (
          <a
            className={cx('cta-buttons--try-api')}
            href="/products/api/financial-news"
            onClick={() => { this.closeMobileMenu(); }}
          >
            Try Our API
          </a>
        )}

        {contactUsLeadsToForm ? (
          <a
            className={cx('cta-buttons--contact-us-to-form', {
              active: path.substr(0, 36) === '/products/api/financial-news/pricing',
              'm-r-20': path.substr(0, 36) === '/products/api/financial-news/pricing',
            })}
            href="#contactus-form"
            onClick={this.scrollToSignupForm}
          >
            {windowWidth > 2800 ? 'GO' : 'Contact Us'}
          </a>
        ) : (
          <a
            className={cx('cta-buttons--contact-us')}
            href="/contact-us"
            target="_self"
          >
            Contact Us
          </a>
        )}
      </div>
    );
  }
}

export default withComponentName(windowWidthDecorator(CTAbuttons));
